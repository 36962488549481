import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import FeaturedCarousel from "../components/FeaturedCarousel"
import MarkdownToHtml from "../components/MarkdownToHtml"
import { DownloadBanner } from "../components/DownloadBanner"

const AboutUs = ({ data, path }) => {
  const { topSection, lawyersSection } = data.markdownRemark.frontmatter

  return (
    <Layout loadMap={false}>
      <SEO
        path={path}
        title="About Us | Learn Everything About Estates Plus"
        description="ESTATES PLUS is the most affordable way to obtain the legal documents necessary to finalise the financial affairs of a loved one who has passed away.We help prepare PROBATE and LETTERS OF ADMINISTRATION saving you up to80%* in legal fees."
        image="/img/Nicholas.png"
      />

      <section className="bg-white">
        <div className="container section-gap">
          <div className="row">
            <div className="col-12">
              <h2 className="font-bold text-center mb-5 mb-md-7">
                We're here to
                <span className="bg-accent d-inline-block text-white px-3 py-2 m-2">
                  help you
                </span>
                make death easier and cheaper
              </h2>

              <MarkdownToHtml
                content={topSection.description}
                className="mb-5 mb-md-7"
              />

              <div className="d-none d-lg-block">
                <PreviewCompatibleImage
                  src={topSection.teamImage}
                  alt={topSection.teamImageAlt}
                />
              </div>
              <div className="d-lg-none">
                <PreviewCompatibleImage
                  src={topSection.teamImageMobile}
                  alt={topSection.teamImageMobileAlt}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container section-gap">
          <div className="row justify-content-center">
            {lawyersSection.list.map(({ image, imageAlt, name, title }, i) => {
              return (
                <div
                  className="col-6 col-lg-4 mb-5 px-4 px-md-6 px-xl-10"
                  key={`teamMember-${i}`}
                >
                  <div className="d-flex flex-column">
                    <PreviewCompatibleImage
                      src={image}
                      className="mb-3"
                      alt={imageAlt}
                    />
                    <span className="font-bold text-uppercase text-center">
                      {name}
                    </span>
                    <span className="text-center">{title}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="bg-white">
        <FeaturedCarousel />
      </section>
      <DownloadBanner />
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
      frontmatter {
        topSection {
          description
          teamImageAlt
          teamImageMobileAlt
          teamImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          teamImageMobile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lawyersSection {
          list {
            name
            title
            imageAlt
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
